export const sideMenuWidth = "400px";
export const toolBarHeight = "80px";

export const palette = {
	purple: "#D2007F",
	green: "#00D264",
	pastelBlue: "#F3F6FD",
	grey: "#27245B20",
	darkerGrey: "#D8D8D8",
	darkGrey: "grey",
	darkBlue: "#27245B",
	background: "#ffffff",
	success: "#00D264",
	error: "#FE4A49",
	black: "#000"
}