import {BoxProps, Button} from "@mui/material";
import {FC, useCallback, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import {WYSIWYG} from "../../../../components/WYSIWYG/WYSIWYG";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {useNavigate} from "react-router-dom";
import {IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	startBtn: {
		marginTop: "30px",
		width: "127px",
		height: "52px",
		fontSize: "16px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	content: {
		"& p": {
			lineHeight: "34px",
			fontSize: "20px",
			[theme.breakpoints.down("sm")]: {
				lineHeight: "25px",
				fontSize: "18px"
			}
		}
	},
	icon: {
		color: theme.palette.secondary.main + "!important", //> R&D needed to remove important
		strokeWidth: "2px",
		stroke: "#fff"
	}
}));

interface IProps extends BoxProps {
	quiz?: IQuiz,
	onContinueActionOverride?: () => true
}

export const QuizStartSummary: FC<IProps> = ({quiz, onContinueActionOverride, ...props}) => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const handleStartAction = useCallback((): void => {
		navigate("active");
	}, [navigate]);

	const startBtn = (
		<Button
			className={classes.startBtn}
			onClick={() => onContinueActionOverride?.() ?? handleStartAction()}
		>
			Start
		</Button>
	);

	useEffect(() => {
		if(quiz && quiz.complete)
			navigate("complete");
	}, [quiz])

	return (
		<CenterTile
			buttons={[startBtn]}
			icon={<FlashOnIcon className={classes.icon}/>}
			title={quiz?.title}
			subtitle={quiz?.subtitle}
		>
			<WYSIWYG className={classes.content}>
				{quiz?.content ?? ""}
			</WYSIWYG>
		</CenterTile>
	)
}