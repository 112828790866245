import {createTheme, Shadows} from "@mui/material";
import {CustomMuiTheme} from "@plumeuk/shapeshift-common/theming";
import {palette, toolBarHeight} from "../constants";

declare module "@mui/material/styles" {
	interface Theme extends CustomMuiTheme {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends CustomMuiTheme{}
}

export const defaultTheme = createTheme({
	toolbarHeight: toolBarHeight,
	breakpoints: {
		//Mobile  |		 x < sm
		//Tablet  |	sm < x < md
		//Desktop |		 x > md
		values: {
			// extra-small
			xs: 0,
			// small
			sm: 700,
			// medium
			md: 1200,
			// large
			lg: 1300,
			// extra-large
			xl: 1536
		}
	},
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		background: {
			default: palette.background
		},
		primary: {
			main: palette.purple
		},
		secondary: {
			main: palette.green
		},
		success: {
			main: palette.success
		},
		error: {
			main: palette.error
		},
		info: {
			main: palette.purple
		}
	},
	typography: {
		fontFamily: "Poppins, sans-serif",
		body1: {
			fontSize: "16px"
		},
		h1: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "50px",
			lineHeight: "120%"
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "46px",
			lineHeight: "120%"
		},
		h3: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "22px",
			lineHeight: "30px"
		},
		h4: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "30px"
		},
		body2: {
			fontSize: "9pt"
		},
		h6: {
			fontSize: "20px"
		},
		button: {
			fontSize: "15px",
			textTransform: "none"
		}
	},
	navBar: {
		statusprofile: {
			backgroundColor: "#CD00A4"
		}
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					background: palette.pastelBlue
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					boxShadow: "0px 3px 12px 0px #d1d1d195",
					height: toolBarHeight,
					backgroundColor: "#fff"
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					"& [ class*='userName']": {
						color: "black"
					},
					"& [ class*='outlineButton']": {
						color: "black",
						borderColor: "blue"
					}
				},
				paper: {
					backgroundColor: palette.pastelBlue + "! important"
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: "5px",
					height: "8px",
					backgroundColor: "#fff"
				},
				bar: {
					backgroundColor: palette.green,
					borderRadius: "10px"
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: "16px",
					"&.Mui-selected": {
						color: "black"
					},
					color: "black"
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid #d1d1d1",
					lineHeight: 0
				},
				indicator: {
					height: "4px",
					backgroundColor: palette.purple
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: "15px",
					backgroundColor: palette.darkBlue,
					color: "white",
					padding: "18px 30px",
					borderRadius: "15px",
					transition: "opacity .3s ease-in-out",
					"&:hover": {
						backgroundColor: palette.darkBlue,
						opacity: 0.8
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				standard: {
					"&:focus": {
						backgroundColor: "transparent"
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: "#fff",
					border: "none",
					padding: "5px 10px",
					borderRadius: "10px"
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 100,
					backgroundColor: palette.darkBlue,
					color: "#fff"
				}
			}
		},
		MuiTable: {
			styleOverrides: {
				root: {
					overflow: "auto"
				}
			}
		}
	}
});
