import {FC} from "react";
import rehypeRaw from "rehype-raw";
import {ReactMarkdown, ReactMarkdownOptions} from "react-markdown/lib/react-markdown";
import {makeStyles} from "tss-react/mui";
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles()((_theme) => ({
	wysiwyg: {
		"& img": {
			maxWidth: "100%"
		}
	}
}));

export const WYSIWYG: FC<ReactMarkdownOptions> = ({className, ...props}) => {
	const {classes, cx} = useStyles();
	const {getUrl} = useFileBaseUrl();

	return (
		<ReactMarkdown
			className={cx(classes.wysiwyg, className)}
			rehypePlugins={[rehypeRaw]}
			components={{
				u: (uProps) => <u style={{textDecoration: "underline"}} {...uProps} />,
				img: ({src, ...imgProps}) =>
					<img
						src={getUrl(src ?? "")}
						{...imgProps}
					/>
			}}
			{...props}
		/>
	);
}