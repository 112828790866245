import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {CourseLibraryPage} from "./pages/CourseLibrary/CourseLibraryPage";
import {CourseLibraryProductPage} from "./pages/CourseLibraryProduct/CourseLibraryProductPage";
import {CoursePage} from "./pages/CoursePage/CoursePage";
import {LoginPage} from "./pages/LoginPage/LoginPage";
import {MyLearningPage} from "./pages/MyLearningPage/MyLearningPage";
import {NotFoundPage} from "./pages/NotFoundPage/NotFoundPage";
import {SandboxPage} from "./pages/Sandbox/sandboxPage";
import {RequireAuth} from "./routes/RequireAuth";
import {MyGroupsPage} from "./pages/MyGroupsPage/MyGroupsPage";
import {GroupPage} from "./pages/GroupPage/GroupPage";
import {RegisterPage} from "./pages/RegisterPage/RegisterPage";
import {GroupSignUpPage} from "./pages/GroupSignUpPage/GroupSignUpPage";
import {ForgottenPasswordPage} from "./pages/ForgottenPassword/ForgottenPassword";
import {ResetPasswordPage} from "./pages/ResetPassword/ResetPassword";

const Main: React.FC = () => {
	return (
		<main style={{height: "100%"}}>
			<BrowserRouter>
				<div className="App">
					<Routes>
						<Route path="/" element={<LoginPage/>} />
						<Route path="/register" element={<RegisterPage />} />
						<Route path="/reset-password" element={<ResetPasswordPage />} />
						<Route path="/forgotten-password" element={<ForgottenPasswordPage/>} />
						<Route path="/group-signup" element={<RequireAuth><GroupSignUpPage /></RequireAuth>} />
						<Route path="/groups-dashboard" element={<RequireAuth><MyGroupsPage/></RequireAuth>} />
						<Route path="/groups-dashboard/:groupSlug/*" element={<RequireAuth><GroupPage/></RequireAuth>} />
						<Route path="/courses" element={<RequireAuth><MyLearningPage/></RequireAuth>} />
						<Route path="/library" element={<RequireAuth><CourseLibraryPage/></RequireAuth>} />
						<Route path="/library/:courseSlug" element={<RequireAuth><CourseLibraryProductPage/></RequireAuth>} />
						<Route path="/course/:courseSlug/*" element={<RequireAuth><CoursePage/></RequireAuth>} />
						<Route path="/sandbox" element={<SandboxPage/>} />
						<Route path="*" element={<NotFoundPage/>} />
					</Routes>
				</div>
			</BrowserRouter>
		</main>
	);
};

export default Main;