import {Box, Button, Typography} from "@mui/material";
import {useApi} from "@plumeuk/shapeshift-identity";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "../../components/WYSIWYG/WYSIWYG";
import {ICourseLibraryBannerResponse} from "../../types/courseLibraryBanner";
import {Link} from "react-router-dom";

interface IStyleProps {
	backgroundUrl?: string,
	desktopContentWidthPx?: number,
	bannerImageMinimumHeightPx?: number
}

const useStyles = makeStyles<IStyleProps>()((theme, {backgroundUrl, desktopContentWidthPx, bannerImageMinimumHeightPx}) => ({
	courseLibraryBanner: {
		borderRadius: "10px",
		color: "white",
		background: backgroundUrl && `url('${backgroundUrl}') no-repeat`,
		backgroundSize: "cover",
		minHeight: bannerImageMinimumHeightPx ? `${bannerImageMinimumHeightPx}px` : "250px",
		[theme.breakpoints.down("sm")]: {
			padding: "20px"
		},
		[theme.breakpoints.up("sm")]: {
			margin: "20px 10px",
			padding: "50px"
		}
	},
	contentContainer: {
		[theme.breakpoints.up("sm")]: {
			width: `${desktopContentWidthPx}px` ?? "100%"
		}
	},
	title: {
		fontSize: "35px",
		lineHeight: "43px",
		fontWeight: 500
	},
	content: {
		"& p": {
			fontSize: "21px"
		}
	},
	btn: {
		background: "#fff",
		height: "52px",
		marginTop: "20px",
		color: theme.palette.secondary.main,
		"&:hover": {
			background: "#fff",
			opacity: 0.85
		}
	}

}));

export const CourseLibraryBanner: FC = () => {
	const [{data: apiData}] = useApi<ICourseLibraryBannerResponse>("/api/course-library-banner?populate=backgroundImage");
	const bannerData = apiData?.data.attributes;
	const {classes} = useStyles({
		backgroundUrl: bannerData?.backgroundImage.data.attributes.url,
		desktopContentWidthPx: bannerData?.desktopContentWidthPx,
		bannerImageMinimumHeightPx: bannerData?.bannerImageMinimumHeightPx
	});

	return (
		<Box className={classes.courseLibraryBanner}>
			<Box className={classes.contentContainer}>
				<Typography className={classes.title}>{bannerData?.title}</Typography>
				<WYSIWYG className={classes.content}>
					{bannerData?.content ?? ""}
				</WYSIWYG>
				{bannerData?.link &&
					<Button
						component={Link}
						to={bannerData.link}
						className={classes.btn}
					>
						Read More
					</Button>}
			</Box>
		</Box>
	);
}